/* Fondo oscuro del calendario */
.fc {
  background-color: #525252;
  color: white;
  border-radius: 8px;
}

/* Cambia el color del header */
.fc .fc-toolbar {
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
}

/* Color de los eventos */
.fc-event {
  background-color: #EC5306 !important;
  border: none !important;
  color: #121212 !important;
  font-weight: bold;
  border-radius: 4px;
}

/* Días sin eventos en gris */
.fc-daygrid-day {
  background-color: #1e1e1e;
  color: white;
}

/* Hover en los días */
.fc-daygrid-day:hover {
  background-color: #333333;
}

/* Botón de hoy */
.fc-button-primary {
  background-color: #EC5306 !important;
  border: none !important;
}

.fc-button-primary:hover {
  background-color: #fa702b !important;
}

.fc-col-header-cell {
  background-color: #EC5306 !important; /* Fondo naranja */
  color: black !important; /* Texto negro */
  font-weight: bold;
  text-transform: uppercase; /* Opcional: poner todo en mayúsculas */
}